import * as React from "react";
import {
	PrismicPreviewProvider,
	componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./src/linkResolver";
// import GeneralContentTemplate from './src/templates/GeneralContentPage';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
	<PrismicPreviewProvider
		repositoryConfigs={[
			{
				repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
				linkResolver,
				// Go update this in gatsby-ssr too
				// componentResolver: componentResolverFromMap({
				//   general_content_page: GeneralContentTemplate,
				// }),
			},
		]}
	>
		{element}
	</PrismicPreviewProvider>
);
