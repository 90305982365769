exports.linkResolver = (doc) => {
	// URL for a category type
	if (doc.type === "general_content_page") {
		return `/${doc.uid}`;
	}

	// URL for a product type
	if (doc.type === "homepage") {
		return `/`;
	}

	// URL for a page type
	if (doc.type === "page") {
		return `/${doc.uid}`;
	}

	// Backup for all other types
	return "/";
};
